































































import { computed, defineComponent, reactive, onMounted, SetupContext, ref, inject } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { useRequest } from '@/hooks/useRequest'
import { NotificationInjectKey } from '@/hooks/useNotification'
import { NavigationInjectKey } from '@/hooks/useNavigation'
type QueryStringType = {
  auth?: string
  link?: string
  state?: string
}
export default defineComponent({
  name: 'Login',
  components: {},
  setup(_, context: SetupContext) {
    const notify = inject(NotificationInjectKey)
    const navi = inject(NavigationInjectKey)
    const { request } = useRequest()
    const error = ref(false)
    const logged = ref(false)
    const inputs = reactive({
      username: '',
      dob: '',
      password: '',
      type: 'authorization',
    })

    const rules = computed(() => {
      return {
        username: {
          required: true,
        },
        password: {
          required: true,
          min: 8,
          max: 18,
          password: true,
        },
        birthday: {
          required: true,
        },
      }
    })

    const submit = async () => {
      error.value = false
      logged.value = true
      const observer = context.refs.observer as InstanceType<typeof ValidationObserver>
      if (!(await observer.validate())) {
        logged.value = false
        return false
      }
      const { method, uri } = context.root.$appConfig.endpoints.usernameLogin
      const result = await request(uri, method, inputs)

      if (result === false) {
        error.value = true
        inputs.password = ''
        observer.reset()
        logged.value = false
        return
      }
      navi?.setNavigation(result.user)
      context.root.$auth.login(result)
      context.root.$router.push('/')
    }

    const onLoginCredentialsByToken = async (query: QueryStringType) => {
      const state = query.state || 'resolve'
      const auth = query.auth || false
      const link = query.link || '/'
      if (state === 'reject') {
        notify?.show('Discordログインに失敗しました。Discordに紐付いたアカウントがありません。', 'error')
        return
      }
      if (auth === false) {
        return
      }
      logged.value = true
      const { method, uri } = context.root.$appConfig.endpoints.login
      const result = await request(uri, method, {
        type: 'refresh',
        token: auth,
      })

      if (!result) {
        notify?.show('ログインに失敗しました。', 'error')
        logged.value = false
        return
      }

      context.root.$auth.login(result)
      context.root.$router.push(link)
    }

    onMounted(async () => {
      await onLoginCredentialsByToken(context.root.$route.query)
      const r = context.root.$route?.query?.state || 'resolve'
      if (r === 'reject') {
        notify?.show('Discordログインに失敗しました。Discordに紐付いたアカウントがありません。', 'error')
      }
    })

    return { logged, error, inputs, rules, submit }
  },
})
